import { FormEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  generateHeaderAppSwitcherItems,
  HeaderAppItem,
  HeaderAppSwitcherUrls
} from '@xq/ui-kit';
import { config } from '@config';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const checkNoMoreData = (
  /** number of items in response from backend */
  itemsLength: number,
  /** number of items in metadata from backend */
  totalItems: number,
  setNoMoreData: (value: boolean) => void
) => {
  const isNoMoreData = itemsLength === totalItems || itemsLength === 0;
  setNoMoreData(isNoMoreData);
};

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export default function ScrollToTop(): any {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

export const generateHeaderAppItems = (
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const headerAppUrls: HeaderAppSwitcherUrls = {
    homeFrontendUrl: config.homeFrontendUrl,
    insightsFrontendUrl: config.insightsFrontendUrl,
    administrationFrontendUrl: config.administrationFrontendUrl,
    omniFrontendUrl: config.omniFrontendUrl
  };

  return generateHeaderAppSwitcherItems(
    headerAppUrls,
    isOrganizationAdmin,
    isXQAdmin
  );
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
