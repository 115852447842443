import { MarketDataSegment } from '@xq/exquance-insights-gateway-frontend-client';
import { ApiTabItem, DataProvider, TabItem } from '../dataTypes';
import { BreadcrumbsDropdownItem, BreadcrumbsItem } from '@xq/ui-kit';
import useProviderName from '@hooks/useProviderName';
import { ProviderNames } from '@constants';

export const getRenamedTabs = (tabs: ApiTabItem[] | []): TabItem[] => {
  return tabs?.map((tab: ApiTabItem) => {
    return {
      label: tab.title,
      key: tab.key,
      // TODO: Investigate why rc-tabs-ink-bar does not render properly when there is only one item.
      simulateContent: tabs.length === 1
    };
  });
};

export const getBreadcrumbsItems = (
  segments: MarketDataSegment[],
  segmentName: string,
  isTrial?: boolean,
  selectedProviderName?: string
): BreadcrumbsItem[] => {
  const result = new Map<
    string,
    { title: string; list: BreadcrumbsDropdownItem[] }
  >();

  segments.flatMap((item) => {
    item.dataProviders.map((dataProvider: DataProvider) => {
      const providerName = useProviderName(
        dataProvider.dataProvider.toLowerCase() as ProviderNames
      );

      if (!result.has(providerName)) {
        result.set(providerName, { title: providerName, list: [] });
      }
      const newItem = {
        label: `${item.name} ${isTrial ? '— Trial version' : ''}`,
        url: `/${item.id.toLowerCase()}/${dataProvider.dataProvider?.toLowerCase()}`,
        dropdown: dataProvider.kpiSets.map(
          (kpiSet: { title: string; key: string }) => ({
            label: `${kpiSet.title} ${isTrial ? '— Trial version' : ''}`,
            url: `${kpiSet.key}`
          })
        )
      };
      result.get(providerName)?.list.push(newItem);
    });
  });

  return [
    {
      label: 'Market structure and performance',
      url: '/'
    },
    {
      label: `${segmentName} ${isTrial ? '— Trial version' : ''}`,
      url: `/${segmentName}/${Array.from(
        result.values()
      )[0]?.title?.toLowerCase()}/`,
      customTitle: selectedProviderName.toLowerCase() === 'kti' ? 'KTI' : null,
      dropdown: Array.from(result.values())
    }
  ];
};
