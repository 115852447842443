export const config = {
  notificationDelay: 5000,
  debounceDelay: 500,
  apiUrl: `${process.env.API_URL}/api`,

  ssoFrontendLoginUrl: process.env.FRONTEND_SSO_LOGIN_URL,
  ssoFrontendSetPasswordUrl: process.env.FRONTEND_SSO_SET_PASSWORD_URL,
  accountFrontendUrl: process.env.FRONTEND_ACCOUNT_URL,
  homeFrontendUrl: process.env.FRONTEND_HOME_URL,
  administrationFrontendUrl: process.env.FRONTEND_ADMINISTRATION_URL,
  omniFrontendUrl: process.env.FRONTEND_OMNI_URL,
  insightsFrontendUrl: process.env.FRONTEND_INSIGHTS_URL,
  logLevel: process.env.APP_LOG_LEVEL || 'log',
  googleMapsApiKey: process.env.FRONTEND_GOOGLE_MAPS_API_KEY,

  defaultLang: 'EN',
  localStorageLang: 'lang'
};
