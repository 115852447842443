import React, { useCallback, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import { config } from '@config';
import { heatmapColors } from '@constants';
import { initialState, reducer } from '@pages/DataSegment/reducers/map';
import useGetUrlParams from '@pages/DataSegment/hooks/useGetUrlParams';
import Legend from '@components/Map/Legend';
import Heatmap from '@components/Map/Heatmap';
import { HeatmapItem, LegendItem, MapShape } from '../dataTypes';
import useApiRequests from './useApiRequests';
import {
  MapMarketDataAreaModel,
  NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
} from '@xq/exquance-insights-gateway-frontend-client';
import { DataProviderName } from 'enums';

// import { KtiDashboardPageControllerGetMarketDataAreaListBySegmentKtiSegmentEnum } from '@xq/exquance-insights-gateway-frontend-client/dist/apis/KtiDashboardPageApi';

interface Params {
  kpiKeys: string[];
  nameOnChart?: string;
  unit?: string;
  kpiMappings: { [key: string]: string };
  map: google.maps.Map;
}

const useDataSegmentMap = ({
  kpiKeys,
  nameOnChart,
  unit,
  kpiMappings,
  map
}: Params) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchMapData, fetchHeatMapData } = useApiRequests();
  const {
    selectedSubmarket,
    selectedKpi,
    selectedDate,
    isMapOpen,
    segmentId,
    providerName,
    setId,
    nestedSubmarket
  } = useGetUrlParams();

  const showMap = !!isMapOpen;
  const mapFullscreen = state.mapFullscreen;
  const realKeys = kpiKeys.map((key) => key.replace('_fake', ''));

  const colorIndex = realKeys?.indexOf(selectedKpi);

  const setMapLegend = (legend: LegendItem[]) => {
    dispatch({ type: 'setMapData', payload: { legend } });
  };

  const setHeatmap = (heatmap: HeatmapItem[]) => {
    dispatch({ type: 'setMapData', payload: { heatmap } });
  };

  const handleShowMap = () => {
    if (!showMap) {
      searchParams.set('isMapOpen', 'true');
    } else {
      searchParams.delete('isMapOpen');
    }
    setSearchParams(searchParams);
  };

  const fetchHeatMap = useCallback(async () => {
    try {
      if (!kpiMappings[selectedKpi]) return;

      const response = await fetchHeatMapData({
        segment: segmentId,
        kpi: kpiMappings[selectedKpi],
        date: new Date(selectedDate),
        kpiSet: setId,
        ...(nestedSubmarket ? { selectedMarket: nestedSubmarket } : {}),
        provider: providerName as DataProviderName
      });

      setMapLegend(response.legend);
      setHeatmap(response.heatmap);
    } catch (error) {
      console.log(error);
    }
  }, [segmentId, selectedKpi, selectedDate, kpiMappings]);

  useEffect(() => {
    if (showMap && segmentId && selectedKpi && selectedDate && kpiMappings) {
      fetchHeatMap();
    }
  }, [
    segmentId,
    selectedKpi,
    showMap,
    selectedDate,
    kpiMappings,
    nestedSubmarket
  ]);

  const handleCloseMap = () => {
    searchParams.delete('isMapOpen');
    setSearchParams(searchParams);
    dispatch({
      type: 'setMapData',
      payload: { mapFullscreen: false }
    });
  };

  const handleFullscreenMap = () => {
    dispatch({ type: 'toggleFullScreen' });
  };

  const showHeatmap = state.legend.length > 0 && selectedDate && selectedKpi;

  const getShapeColor = useCallback(
    (submarket: string) => {
      const order = state.heatmap?.find(
        (item: HeatmapItem) => item?.submarket === submarket
      )?.rangeOrder;
      if (!order) {
        return '#DADADA';
      }

      return heatmapColors[colorIndex]?.[order - 1];
    },
    [colorIndex, state.heatmap]
  );

  const mapOptions = {
    disableBaseCentering: true,
    center: state.activeShape
      ? {
          lat: state.activeShape?.center?.lat ?? 0,
          lng: state.activeShape?.center?.lng ?? 0
        }
      : { lat: 60.20579, lng: 24.846735 },
    shapes: state.shapes?.map((shape) => {
      const coordinates = shape.geometry.coordinates.map(
        (coordinates: number[]) => ({
          lat: coordinates?.[0],
          lng: coordinates?.[1]
        })
      );

      const kpiValue = state.heatmap
        ?.find((item: HeatmapItem) => item.submarket === shape?.submarket)
        ?.value?.toString();

      return {
        id: shape.gid,
        submarket: shape.submarket,
        kpi: selectedKpi,
        kpiValue,
        strokeColor: getShapeColor(shape.submarket) || '#0063F7',
        fillColor: getShapeColor(shape.submarket) || '#0063F7',
        fillOpacity: 0.6,
        coordinates,
        onClick: () => {
          searchParams.set('submarket', shape.submarket);
          setSearchParams(searchParams);
        }
      };
    }),
    bottomData: showHeatmap ? (
      <Heatmap legend={state.legend} colorIndex={colorIndex} />
    ) : null,
    headerData: (
      <Legend
        providerName={providerName}
        nameOnChart={nameOnChart}
        segmentId={segmentId}
        unit={unit}
        selectedDate={selectedDate}
      />
    ),
    activeShape: state.activeShape,
    onFullscreenClick: handleFullscreenMap,
    onCloseClick: handleCloseMap,
    googleMapsApiKey: config.googleMapsApiKey,
    loader: !state.activeShape || !state.heatmap
  };

  const fetchMapShapes = async (segmentId: string, setId: string) => {
    try {
      const mapShapesItems: MapMarketDataAreaModel[] = [];
      const { items: initialItems } = await fetchMapData({
        provider: providerName as DataProviderName,
        newsecOptions: {
          segment:
            segmentId as NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
        }
        // ktiOptions: {
        //   date: new Date(),
        //   kpiSet: setId,
        //   // ktiSegment:
        //   //   segmentId as KtiDashboardPageControllerGetMarketDataAreaListBySegmentKtiSegmentEnum,
        //   ...(nestedSubmarket ? { selectedMarket: nestedSubmarket } : {})
        // }
      });

      mapShapesItems.push(...initialItems);

      if (providerName === 'kti' && nestedSubmarket) {
        if (!state.shapes.length) {
          const { items: additionalItems } = await fetchMapData({
            provider: providerName as DataProviderName,
            newsecOptions: {
              segment:
                segmentId as NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
            }
            // ktiOptions: {
            //   date: new Date(),
            //   kpiSet: setId
            //   // ktiSegment:
            //   //   segmentId as KtiDashboardPageControllerGetMarketDataAreaListBySegmentKtiSegmentEnum
            // }
          });

          additionalItems.forEach((item) => {
            if (
              !mapShapesItems.some(
                (existingItem) => existingItem.gid === item.gid
              )
            ) {
              mapShapesItems.unshift(item);
            }
          });
        } else {
          state.shapes.forEach((item: MapShape) => {
            if (
              !mapShapesItems.some(
                (existingItem) => existingItem.gid === item?.gid.toString()
              )
            ) {
              mapShapesItems.unshift({ ...item, gid: item.gid.toString() });
            }
          });
        }
      }

      dispatch({
        type: 'setMapData',
        payload: {
          shapes: mapShapesItems.map((item) => ({
            ...item,
            gid: item.gid as unknown as number
          }))
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showMap && selectedSubmarket) {
      fetchMapShapes(segmentId, setId);
    }
  }, [showMap, segmentId, setId, state.zoomLevel, nestedSubmarket]);

  useEffect(() => {
    if (showMap && selectedSubmarket && state.shapes.length > 0) {
      const activeShape = state.shapes?.find(
        (shape: MapShape) => shape.submarket === selectedSubmarket
      );

      const kpiValue = state.heatmap?.find(
        (item: HeatmapItem) => item.submarket === activeShape?.submarket
      )?.formattedValue;

      dispatch({
        type: 'setMapData',
        payload: {
          activeShape: {
            id: activeShape?.gid,
            center: {
              lat: activeShape?.geometry?.coordinates?.[0]?.[0] ?? 0,
              lng: activeShape?.geometry?.coordinates?.[0]?.[1] ?? 0
            },
            kpi: selectedKpi,
            ...(kpiValue ? { kpiValue } : {})
          }
        }
      });
    }
  }, [selectedSubmarket, showMap, state.shapes, state.heatmap]);

  return {
    mapOptions,
    handleShowMap,
    handleFullscreenMap,
    handleCloseMap,
    showMap: !!isMapOpen,
    mapFullscreen
  };
};
export default useDataSegmentMap;
