import {
  NewsecListSortOptionsDTOFieldNameEnum,
  NewsecListSortOptionsDTOOrderEnum,
  TableColumn
} from '@xq/exquance-insights-gateway-frontend-client';
import { ApiRowItem } from '@pages/DataSegment/dataTypes';
import { changeSortDirection, TableDataType, TableRow } from '@xq/ui-kit';

type State = {
  sortBy: NewsecListSortOptionsDTOFieldNameEnum;
  sortOrder: NewsecListSortOptionsDTOOrderEnum;
  searchString: string;
  tableData: ApiRowItem[] | [];
  comparisonDate: Date | null;
  tableColumns: TableColumn[];
  isTableLoading: boolean;
  activeColumn: string;
  selectedRow: TableRow;
  tableDataType: TableDataType;
  defaultPublishDate: number | null;
  page: number;
  noMoreData: boolean;
};

type ResetAction = {
  type: 'reset';
};

type SetSortAction = {
  type: 'setSort';
  field: NewsecListSortOptionsDTOFieldNameEnum;
};

type SetDataSegmentDataAction = {
  type: 'setDataSegmentData';
  payload: Partial<State>;
};

type SetIsTableLoadingAction = {
  type: 'setIsTableLoading';
  isTableLoading: boolean;
};

type SetTableData = {
  type: 'setTableData';
  payload: ApiRowItem[];
};

type Action =
  | ResetAction
  | SetDataSegmentDataAction
  | SetSortAction
  | SetIsTableLoadingAction
  | SetTableData;

const initialState: State = {
  sortBy: 'submarket',
  sortOrder: 'ASC',
  searchString: '',
  tableData: [],
  comparisonDate: null,
  tableColumns: [],
  isTableLoading: false,
  activeColumn: null,
  selectedRow: null,
  defaultPublishDate: null,
  tableDataType: 'absolute',
  page: 1,
  noMoreData: false
};

function reducer(state: State, action: Action) {
  const { type } = action;

  switch (type) {
    case 'setDataSegmentData':
      return { ...state, ...action.payload };

    case 'setIsTableLoading':
      return { ...state, isTableLoading: action.isTableLoading };

    case 'setTableData':
      return { ...state, tableData: action.payload };

    case 'setSort':
      return {
        ...state,
        sortBy: action.field,
        sortOrder: changeSortDirection(state.sortOrder)
      };

    case 'reset':
      return {
        ...state,
        activeColumn: null,
        selectedRow: null
      };

    default:
      break;
  }
}

export { initialState, reducer };
