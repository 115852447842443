import { exquanceInsightsApiConfiguration } from '@services';
import {
  NewsecDashboardPageApi,
  NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
} from '@xq/exquance-insights-gateway-frontend-client';
import {
  FetchChartDataParams,
  FetchHeatMapDataParams,
  FetchSubmarketDataParams,
  FetchTableColsDataParams,
  FetchTableRowsParams,
  KtiSegments,
  NewsecSegments,
  OptionsParams
} from '../dataTypes';
import interceptor from '@services/http';
import { DataProviderName } from '@enums';
// import { KtiDashboardPageApiKtiDashboardPageControllerGetMarketDataAreaListBySegmentRequest } from '@xq/exquance-insights-gateway-frontend-client/dist/apis/KtiDashboardPageApi';

const useApiRequests = () => {
  const newsecGateway = new NewsecDashboardPageApi(
    exquanceInsightsApiConfiguration
  );

  // const ktiGateway = new KtiDashboardPageApi(exquanceInsightsApiConfiguration);

  const getKpiSets = async (
    segment: NewsecSegments | KtiSegments,
    providerName: string
  ) => {
    switch (providerName) {
      case DataProviderName.newsec: {
        const res = await interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetPageDataRaw({
              newsecSegment: segment
            })
        );
        return res.raw.json();
      }
      // case DataProviderName.kti: {
      //   const res = await interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetPageDataRaw({
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         // @ts-expect-error
      //         ktiSegment: segment
      //       })
      //   );
      //   return res.raw.json();
      // }
    }
    // const res = await interceptor(
    //   async () =>
    //     await newsecGateway.newsecDashboardPageControllerGetPageDataRaw({
    //       newsecSegment: segment
    //     })
    // );
    // return res.raw.json();
  };

  const fetchTableRowsData = async (params: FetchTableRowsParams) => {
    const {
      provider,
      kpi,
      sortOrder,
      sortBy,
      searchString,
      segment,
      isPercentView,
      date,
      selectedMarket,
      marketId
    } = params;
    const optionsObject: OptionsParams = {
      sort: [
        {
          order: sortOrder,
          fieldName: sortBy
        }
      ],
      isPercentView,
      searchString,
      date,
      selectedMarket
    };

    switch (provider) {
      case DataProviderName.newsec: {
        return interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetSubMarketList({
              kpiSet: kpi,
              newsecSegment: segment,
              newsecListOptionsDTORequest: optionsObject
            })
        );
      }
      // case DataProviderName.kti: {
      //   return interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetSubMarketList({
      //         kpiSet: kpi,
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         // @ts-expect-error
      //         ktiSegment: segment,
      //         selectedSubmarket: marketId,
      //         ktiListOptionsDTORequest:
      //           optionsObject as KtiListOptionsDTORequest
      //       })
      //   );
      // }
    }
  };

  const fetchTableColsData = async ({
    provider,
    kpi,
    segment
  }: FetchTableColsDataParams) => {
    switch (provider) {
      case DataProviderName.newsec: {
        return interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetSubMarketTableColumns(
              {
                kpiSet: kpi,
                newsecSegment: segment
              }
            )
        );
      }
      // case DataProviderName.kti: {
      //   return interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetSubMarketTableColumns(
      //         {
      //           kpiSet: kpi,
      //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //           // @ts-expect-error
      //           ktiSegment: segment
      //         }
      //       )
      //   );
      // }
    }
  };

  const fetchChartData = async ({
    provider,
    selectedSubmarket,
    segment,
    kpiGroup = undefined,
    kpi = undefined,
    kpiSet,
    marketId
  }: FetchChartDataParams) => {
    switch (provider) {
      case DataProviderName.newsec: {
        return await interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetSubMarketChartData(
              {
                selectedSubmarket,
                kpiGroup,
                kpi,
                newsecSegment: segment
              }
            )
        );
      }
      // case DataProviderName.kti: {
      //   return await interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetSubMarketChartData({
      //         selectedSubmarket,
      //         kpiGroup,
      //         kpi,
      //         marketId,
      //         kpiSet,
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         // @ts-expect-error
      //         ktiSegment: segment
      //       })
      //   );
      // }
    }
  };

  interface FetchMapDataParams {
    provider: DataProviderName;
    newsecOptions?: {
      segment: NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum;
    };
    // ktiOptions?: KtiDashboardPageApiKtiDashboardPageControllerGetMarketDataAreaListBySegmentRequest;
  }

  const fetchMapData = async ({
    provider,
    newsecOptions
  }: FetchMapDataParams) => {
    switch (provider) {
      case DataProviderName.newsec:
        return interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetMarketDataAreaListBySegment(
              {
                newsecSegment: newsecOptions?.segment
              }
            )
        );
      // case DataProviderName.kti:
      //   return interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetMarketDataAreaListBySegment(
      //         {
      //           ...ktiOptions
      //         }
      //       )
      //   );
      default:
        return null;
    }
  };

  const fetchSubmarketData = async (params: FetchSubmarketDataParams) => {
    const {
      kpi,
      sortOrder,
      sortBy,
      selectedSubmarket,
      segment,
      isPercentView,
      provider,
      marketId
    } = params;
    const optionsObject = {
      sort: [
        {
          order: sortOrder,
          fieldName: sortBy
        }
      ],
      isPercentView,
      selectedSubmarket
    };

    switch (provider) {
      case DataProviderName.newsec: {
        return interceptor(
          async () =>
            await newsecGateway.newsecDashboardPageControllerGetListForSubmarket(
              {
                kpiSet: kpi,
                newsecSegment: segment,
                newsecListForSubmarketOptionsDTORequest: optionsObject
              }
            )
        );
      }
      // case DataProviderName.kti: {
      //   return interceptor(
      //     async () =>
      //       await ktiGateway.ktiDashboardPageControllerGetListForSubmarket({
      //         kpiSet: kpi,
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         // @ts-expect-error
      //         ktiSegment: segment,
      //         ktiListForSubmarketOptionsDTORequest: {
      //           ...optionsObject,
      //           marketId
      //         }
      //       })
      //   );
      // }
    }
  };

  const fetchHeatMapData = async (params: FetchHeatMapDataParams) => {
    return interceptor(async () => {
      switch (params.provider) {
        case DataProviderName.newsec: {
          return await newsecGateway.newsecDashboardPageControllerGetHeatmap({
            ...params,
            newsecSegment: params.segment as NewsecSegments
          });
        }
        // case DataProviderName.kti: {
        //   return await ktiGateway.ktiDashboardPageControllerGetHeatmap({
        //     ...params,
        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //     // @ts-expect-error
        //     ktiSegment: params.segment
        //   });
        // }
      }
    });
  };

  return {
    getKpiSets,
    fetchTableRowsData,
    fetchTableColsData,
    fetchChartData,
    fetchSubmarketData,
    fetchMapData,
    fetchHeatMapData
  };
};

export default useApiRequests;
